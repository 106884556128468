define("wavelengthspace/components/nav-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="container-fluid footer mt-5 pt-5 pb-5">
  	<div class="row">
  		<div class="col-12 col-xl-8 offset-xl-2">
  			<Firebase-auth/>
  		</div>
  	</div>
  </div>
  
  {{yield}}
  */
  {
    "id": "Hm1EDVIK",
    "block": "[[[10,0],[14,0,\"container-fluid footer mt-5 pt-5 pb-5\"],[12],[1,\"\\n\\t\"],[10,0],[14,0,\"row\"],[12],[1,\"\\n\\t\\t\"],[10,0],[14,0,\"col-12 col-xl-8 offset-xl-2\"],[12],[1,\"\\n\\t\\t\\t\"],[8,[39,0],null,null,null],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\\t\"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[18,1,null]],[\"&default\"],false,[\"firebase-auth\",\"yield\"]]",
    "moduleName": "wavelengthspace/components/nav-footer.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});