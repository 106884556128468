define("wavelengthspace/templates/exhibition/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yWSxlw+P",
    "block": "[[[1,[28,[35,0],[\"Exhibition\"],null]],[1,\"\\n \"],[10,0],[14,0,\"container-fluid exhibition\"],[12],[1,\"\\n \\t\"],[10,0],[14,0,\"row\"],[12],[1,\"\\n \\t\\t\"],[10,0],[14,0,\"col-12 splash\"],[15,5,[29,[\"background-image:url(https://firebasestorage.googleapis.com/v0/b/wavelengthspace.appspot.com/o/images%2F\",[30,0,[\"model\",\"exhibition\",\"coverPhoto\"]],\"?alt=media&token=1c340349-553a-4998-949d-81a5eadf67b9)\"]]],[12],[1,\"\\n \\t\\t\\t\"],[10,0],[14,0,\"row text-center\"],[12],[1,\"\\n \\t\\t\\t\\t\"],[10,0],[14,0,\"col-10 offset-1 col-xl-6 offset-xl-5\"],[12],[1,\"\\n \\t\\t\\t\\t\\t\"],[10,\"h1\"],[12],[1,[30,0,[\"model\",\"exhibition\",\"title\"]]],[13],[1,\"\\n \\t\\t\\t\\t\"],[13],[1,\"\\n \\t\\t\\t\"],[13],[1,\"\\n \\t\\t\"],[13],[1,\"\\n \\t\"],[13],[1,\"\\n \\t\"],[10,0],[14,0,\"row mt-3\"],[12],[1,\"\\n \\t\\t\"],[10,0],[14,0,\"col-12 col-xl-8 offset-xl-1\"],[12],[1,\"\\n \\t\\t\\t\"],[10,\"h2\"],[12],[1,\"Curator's Statement\"],[13],[1,\"\\n \\t\\t\\t\"],[10,2],[12],[1,[30,0,[\"model\",\"exhibition\",\"description\"]]],[13],[1,\"\\n \\t\\t\"],[13],[1,\"\\n \\t\"],[13],[1,\"\\n    \"],[8,[39,1],null,[[\"@photos\",\"@exhibitionURL\"],[[30,0,[\"model\",\"imagery\"]],[30,0,[\"model\",\"exhibition\",\"url\"]]]],null],[1,\"\\n \"],[13],[1,\"\\n\\n\"],[46,[28,[37,3],null,null],null,null,null]],[],false,[\"page-title\",\"display-imagery\",\"component\",\"-outlet\"]]",
    "moduleName": "wavelengthspace/templates/exhibition/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});