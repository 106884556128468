define("wavelengthspace/services/firebase-storage", ["exports", "firebase/storage"], function (_exports, _storage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FirebaseStorageService extends Ember.Service {
    async newImage(fileName, file) {
      const storage = (0, _storage.getStorage)();
      const storageRef = (0, _storage.ref)(storage);
      const imagesRef = (0, _storage.ref)(storageRef, 'images');
      const imageRef = (0, _storage.ref)(imagesRef, fileName);
      const imageFullPathRef = (0, _storage.ref)(storage, imageRef.fullPath);
      return await (0, _storage.uploadBytes)(imageFullPathRef, file);
    }

    async removeImage(fileName) {
      const storage = (0, _storage.getStorage)();
      const storageRef = (0, _storage.ref)(storage);
      const imagesRef = (0, _storage.ref)(storageRef, 'images');
      const imageRef = (0, _storage.ref)(imagesRef, fileName);
      return await (0, _storage.deleteObject)(imageRef);
    } //may need to construct the URLs at some point
    // @action
    // getImageURL(fileName){
    // 	const storage = getStorage();
    // 	const storageRef = ref(storage);
    // 	const imagesRef = ref(storageRef, 'images');	
    // 	fileName.forEach(file => {
    // 		let fileRef = ref(imagesRef, file);
    // 		let urls = getDownloadURL(fileRef).then((url) => { 
    // 			console.log(url);
    // 		});	
    // 	});
    // }


  }

  _exports.default = FirebaseStorageService;
});