define("wavelengthspace/routes/book", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BookRoute extends Ember.Route {
    beforeModel() {
      window.location.replace('https://booking.appointy.com/visitwavelength?&cat=326947&_ga=2.249691590.1335591819.1673120122-910430093.1635977984');
    }

  }

  _exports.default = BookRoute;
});