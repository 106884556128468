define("wavelengthspace/components/g-map/polygon", ["exports", "ember-google-maps/components/g-map/polygon"], function (_exports, _polygon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _polygon.default;
    }
  });
});