define("wavelengthspace/adapters/application", ["exports", "@ember-data/adapter/json-api", "firebase/database"], function (_exports, _jsonApi, _database) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ApplicationAdapter extends _jsonApi.default {}

  _exports.default = ApplicationAdapter;
});