define("wavelengthspace/components/nav-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="container mb-3">
  	<BsNavbar
  	  @type="light"
  	  @backgroundColor="light"
  	  as |navbar|
  	>
  	  <LinkTo @route="index" class="navbar-brand col">
  	  	<img src="/assets/images/WavelengthSpaceLogo.png" width="auto" height="60">
  	  </LinkTo>
  	  <navbar.toggle/>
  	  <navbar.content class="col justify-content-end">
  	    <navbar.nav as |nav|>
  	      <nav.item>
  	        <nav.link-to @route="exhibitions">Exhibitions</nav.link-to>
  	      </nav.item>
  	      <nav.item>
  	        <nav.link-to @route="shop">Shop</nav.link-to>
  	      </nav.item>
  	      <nav.item>
  	        <nav.link-to @route="about">About</nav.link-to>
  	      </nav.item>
  	      <nav.item>
  	        <nav.link-to @route="book">Book Appointment</nav.link-to>
  	      </nav.item>
  	    </navbar.nav>
  	  </navbar.content>
  	</BsNavbar>
  </div>
  {{yield}}
  */
  {
    "id": "j8KjPCXn",
    "block": "[[[10,0],[14,0,\"container mb-3\"],[12],[1,\"\\n\\t\"],[8,[39,0],null,[[\"@type\",\"@backgroundColor\"],[\"light\",\"light\"]],[[\"default\"],[[[[1,\"\\n\\t  \"],[8,[39,1],[[24,0,\"navbar-brand col\"]],[[\"@route\"],[\"index\"]],[[\"default\"],[[[[1,\"\\n\\t  \\t\"],[10,\"img\"],[14,\"src\",\"/assets/images/WavelengthSpaceLogo.png\"],[14,\"width\",\"auto\"],[14,\"height\",\"60\"],[12],[13],[1,\"\\n\\t  \"]],[]]]]],[1,\"\\n\\t  \"],[8,[30,1,[\"toggle\"]],null,null,null],[1,\"\\n\\t  \"],[8,[30,1,[\"content\"]],[[24,0,\"col justify-content-end\"]],null,[[\"default\"],[[[[1,\"\\n\\t    \"],[8,[30,1,[\"nav\"]],null,null,[[\"default\"],[[[[1,\"\\n\\t      \"],[8,[30,2,[\"item\"]],null,null,[[\"default\"],[[[[1,\"\\n\\t        \"],[8,[30,2,[\"link-to\"]],null,[[\"@route\"],[\"exhibitions\"]],[[\"default\"],[[[[1,\"Exhibitions\"]],[]]]]],[1,\"\\n\\t      \"]],[]]]]],[1,\"\\n\\t      \"],[8,[30,2,[\"item\"]],null,null,[[\"default\"],[[[[1,\"\\n\\t        \"],[8,[30,2,[\"link-to\"]],null,[[\"@route\"],[\"shop\"]],[[\"default\"],[[[[1,\"Shop\"]],[]]]]],[1,\"\\n\\t      \"]],[]]]]],[1,\"\\n\\t      \"],[8,[30,2,[\"item\"]],null,null,[[\"default\"],[[[[1,\"\\n\\t        \"],[8,[30,2,[\"link-to\"]],null,[[\"@route\"],[\"about\"]],[[\"default\"],[[[[1,\"About\"]],[]]]]],[1,\"\\n\\t      \"]],[]]]]],[1,\"\\n\\t      \"],[8,[30,2,[\"item\"]],null,null,[[\"default\"],[[[[1,\"\\n\\t        \"],[8,[30,2,[\"link-to\"]],null,[[\"@route\"],[\"book\"]],[[\"default\"],[[[[1,\"Book Appointment\"]],[]]]]],[1,\"\\n\\t      \"]],[]]]]],[1,\"\\n\\t    \"]],[2]]]]],[1,\"\\n\\t  \"]],[]]]]],[1,\"\\n\\t\"]],[1]]]]],[1,\"\\n\"],[13],[1,\"\\n\"],[18,3,null]],[\"navbar\",\"nav\",\"&default\"],false,[\"bs-navbar\",\"link-to\",\"yield\"]]",
    "moduleName": "wavelengthspace/components/nav-bar.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});