define("wavelengthspace/helpers/format-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function formatDate(params) {
    let dateParts = params.toString().split('-');
    let year = dateParts[0];
    let month = dateParts[1] - 1;
    let day = dateParts[2];
    let date = new Date(year, month, day);
    let options = {
      month: 'long',
      year: 'numeric'
    };
    return date.toLocaleString('en-US', options);
  });

  _exports.default = _default;
});