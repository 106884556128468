define("wavelengthspace/initializers/fire-base", ["exports", "firebase/app", "wavelengthspace/config/environment"], function (_exports, _app, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    // application.inject('route', 'foo', 'service:foo');
    const app = (0, _app.initializeApp)(_environment.default.firebaseConfig);
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});