define("wavelengthspace/templates/exhibition/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Sn5FhKAh",
    "block": "[[[1,[28,[35,0],[\"Edit Exhibition\"],null]],[1,\"\\n \\n\"],[8,[39,1],null,[[\"@model\"],[[30,0,[\"model\"]]]],null],[1,\"\\n\\n\"],[46,[28,[37,3],null,null],null,null,null]],[],false,[\"page-title\",\"edit-exhibition\",\"component\",\"-outlet\"]]",
    "moduleName": "wavelengthspace/templates/exhibition/edit.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});