define("wavelengthspace/routes/imagery/add", ["exports", "firebase/database"], function (_exports, _database) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ImageryAddRoute = (_dec = Ember.inject.service('firebase-auth'), _dec2 = Ember.inject.service('scroll-top'), (_class = class ImageryAddRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "fbAuth", _descriptor, this);

      _initializerDefineProperty(this, "scrollTop", _descriptor2, this);
    }

    beforeModel() {
      this.fbAuth.checkAuth();
      this.scrollTop.activate();
    }

    async model(params) {
      const db = (0, _database.getDatabase)();
      const dbRef = (0, _database.ref)(db);
      let imageryRef = (0, _database.query)((0, _database.ref)(db, 'imagery/'), (0, _database.orderByChild)('url'), (0, _database.equalTo)(params.id));
      let maxImagerySeq = await (0, _database.get)(imageryRef).then(snapshot => {
        let snapJson = snapshot.toJSON();
        let maxSequence = [];
        Object.values(snapJson).forEach(v => {
          maxSequence.push(v.imageSequence);
        });
        return Math.max(...maxSequence);
      }).catch(error => {
        return 1010;
      });
      const newObj = {};
      let maxSequenceObj = {
        "maxSequence": maxImagerySeq
      };
      let key;

      for (key in params) {
        if (params.hasOwnProperty(key)) {
          newObj[key] = params[key];
        }
      }

      for (key in maxSequenceObj) {
        if (maxSequenceObj.hasOwnProperty(key)) {
          newObj[key] = maxSequenceObj[key];
        }
      }

      return newObj;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fbAuth", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "scrollTop", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ImageryAddRoute;
});