define("wavelengthspace/routes/shop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ShopRoute extends Ember.Route {
    beforeModel() {
      window.location.replace('https://www.artsy.net/partner/wavelength-space');
    }

  }

  _exports.default = ShopRoute;
});