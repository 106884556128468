define("wavelengthspace/helpers/load", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.load = load;
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AsyncData = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, (_class = class AsyncData {
    constructor() {
      _initializerDefineProperty(this, "state", _descriptor, this);

      _initializerDefineProperty(this, "value", _descriptor2, this);

      _initializerDefineProperty(this, "error", _descriptor3, this);
    }

    resolveWith(value) {
      this.state = 'LOADED';
      this.value = value;
      ;
    }

    rejectWith(error) {
      this.state = 'ERROR';
      this.error = error;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "state", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'LOADING';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "value", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "error", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));

  function load(somePromise) {
    let asyncData = new AsyncData();
    somePromise.then(value => asyncData.resolveWith(value), error => asyncData.rejectWith(error));
    return asyncData;
  }

  var _default = Ember.Helper.helper(([promise]) => load(promise));

  _exports.default = _default;
});