define("wavelengthspace/templates/imagery/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eZFLiB9w",
    "block": "[[[1,[28,[35,0],[\"Add Imagery\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@url\",\"@maxSeq\"],[[30,0,[\"model\",\"id\"]],[30,0,[\"model\",\"maxSequence\"]]]],null],[1,\"\\n\\n\"],[46,[28,[37,3],null,null],null,null,null]],[],false,[\"page-title\",\"add-imagery\",\"component\",\"-outlet\"]]",
    "moduleName": "wavelengthspace/templates/imagery/add.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});