define("wavelengthspace/components/firebase-auth", ["exports", "@glimmer/component", "firebase/auth"], function (_exports, _component, _auth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#unless this.fbAuth.loggedIn}}
  	<button class="btn btn-dark" {{on "click" this.gSignIn}}>Login</button>
  	{{else}}
  	<span>{{this.fbAuth.fullName}}</span>
  {{/unless}}
  
  {{#if this.fbAuth.superUser}}<button class="btn">Edit</button>{{/if}}
  {{yield}}
  */
  {
    "id": "Ao2n55SW",
    "block": "[[[41,[51,[30,0,[\"fbAuth\",\"loggedIn\"]]],[[[1,\"\\t\"],[11,\"button\"],[24,0,\"btn btn-dark\"],[4,[38,1],[\"click\",[30,0,[\"gSignIn\"]]],null],[12],[1,\"Login\"],[13],[1,\"\\n\"]],[]],[[[1,\"\\t\"],[10,1],[12],[1,[30,0,[\"fbAuth\",\"fullName\"]]],[13],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[30,0,[\"fbAuth\",\"superUser\"]],[[[10,\"button\"],[14,0,\"btn\"],[12],[1,\"Edit\"],[13]],[]],null],[1,\"\\n\"],[18,1,null]],[\"&default\"],false,[\"unless\",\"on\",\"if\",\"yield\"]]",
    "moduleName": "wavelengthspace/components/firebase-auth.hbs",
    "isStrictMode": false
  });

  let FirebaseAuthComponent = (_dec = Ember.inject.service('firebase-auth'), _dec2 = Ember._action, (_class = class FirebaseAuthComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "fbAuth", _descriptor, this);
    }

    gSignIn() {
      const auth = (0, _auth.getAuth)();
      (0, _auth.setPersistence)(auth, _auth.inMemoryPersistence).then(() => {
        const provider = new _auth.GoogleAuthProvider();
        (0, _auth.signInWithRedirect)(auth, provider).then(result => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = _auth.GoogleAuthProvider.credentialFromResult(result);

          const token = credential.accessToken; // The signed-in user info.

          const user = result.user; // ...
        }).catch(error => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message; // The email of the user's account used.

          const email = error.email; // The AuthCredential type that was used.

          const credential = _auth.GoogleAuthProvider.credentialFromError(error);

          console.log(errorMessage);
        });
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fbAuth", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "gSignIn", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "gSignIn"), _class.prototype)), _class));
  _exports.default = FirebaseAuthComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FirebaseAuthComponent);
});