define("wavelengthspace/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DHCqvkHy",
    "block": "[[[1,[34,0]],[1,\"\\n\"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\"],[1,[34,3]]],[],false,[\"nav-bar\",\"component\",\"-outlet\",\"nav-footer\"]]",
    "moduleName": "wavelengthspace/templates/application.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});